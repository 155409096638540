import {useAccount, useContract, useContractRead, useProvider} from 'wagmi'
import React, {useEffect, useState} from "react";
import BirdGridItemCard from "./BirdGridItemCard";
import utils from "../Utils";
import toast from 'react-hot-toast';


export default function ActiveListings(props) {
    const {
        birdswapContractAddress,
        birdswapContractAbi
    } = props;

    const { address, isConnected } = useAccount();

    const [listings, setListings] = useState([]);

    const provider = useProvider();

    const { data: birdswapContractData, isError: birdswapContractError, isLoading: isBirdSwapContractDataLoading } = useContractRead({
        addressOrName: birdswapContractAddress,
        contractInterface: birdswapContractAbi,
        functionName: 'getAsksForMoonbirds',
        cacheOnBlock: true
    });

        const contract = useContract({
        addressOrName: birdswapContractAddress,
        contractInterface: birdswapContractAbi,
        signerOrProvider: provider
    });

    useEffect(() => {
        if (contract && isConnected) {
            const fetchData = async () => {

                const fetchedListings = [];
                let allTokenIdToLatestAsk = {};
                // get listings where I'm the seller
                try {
                    const filter = contract.filters.AskCreated(null, address);
                    const _listings = await contract.queryFilter(filter);
                    const tokenIdToLatestAsk = utils.getLatestAsksFromAskCreatedEvents(_listings);
                    Object.assign(allTokenIdToLatestAsk, tokenIdToLatestAsk);
                    fetchedListings.push(...Object.values(tokenIdToLatestAsk));

                    setListings(_listings);
                } catch (error) {
                    console.error('Failed to fetch active listings.', error);
                    toast.error('Failed to fetch active listings.');
                }

                // get listings where I'm the buyer
                try {
                    const filter = contract.filters.AskCreated(null, null, address);
                    const _listings = await contract.queryFilter(filter);
                    const tokenIdToLatestAsk = utils.getLatestAsksFromAskCreatedEvents(_listings);

                    // merge objects but make sure to override ask by token id only if its a newer block
                    for (let tokenId in tokenIdToLatestAsk) {
                        if (tokenIdToLatestAsk.hasOwnProperty(tokenId)) {
                            if (allTokenIdToLatestAsk.hasOwnProperty(tokenId)) {
                                allTokenIdToLatestAsk[tokenId] =
                                    allTokenIdToLatestAsk[tokenId].blockNumber > tokenIdToLatestAsk[tokenId].blockNumber ?
                                        allTokenIdToLatestAsk[tokenId] : tokenIdToLatestAsk[tokenId];
                            } else {
                                allTokenIdToLatestAsk[tokenId] = tokenIdToLatestAsk[tokenId];
                            }
                        }
                    }

                    fetchedListings.push(...Object.values(tokenIdToLatestAsk));
                    setListings(fetchedListings);
                } catch (error) {
                    console.error('Failed to fetch active listings.', error);
                    toast.error('Failed to fetch active listings.');
                }


                try {
                    const asks = await contract.getAsksForMoonbirds(Object.keys(allTokenIdToLatestAsk));
                    const filteredListings = [];
                    // remove any asks that are no longer active. we do this by
                    // checking against the asks stored on the latest block
                    for (let i = 0; i < Object.keys(allTokenIdToLatestAsk).length; i++) {
                        const listing = allTokenIdToLatestAsk[Object.keys(allTokenIdToLatestAsk)[i]];
                        if (listing.args.uid === asks[i].uid) {
                            filteredListings.push(listing);
                        }
                    }
                    setListings(filteredListings);
                } catch (error) {
                    console.error('Failed to fetch latest asks.', error);
                    // dont show any toast
                }

            };
            fetchData();
        }
    }, [contract, isConnected]);

    const renderActiveListing = (listing, i) => {
        return <BirdGridItemCard
            key={i}
            imgSrc={utils.getMoonbirdImgSrc(listing?.args?.tokenId)}
            tokenId={listing?.args?.tokenId}
            ctaButtonText="View listing"
            onCTAButtonClick={() =>{}}
            owner={listing?.args?.seller}
            salePriceWei={listing?.args?.askPrice}
            navigateTo={`/bird/${listing?.args?.tokenId}`}
            buyer={listing?.args?.buyer}
            network={props.network}
        />;
    };

    if (listings.length ===0) {
        return (
            <>
                <div className="text-2xl">Active listings</div>
                <div className="bg-slate-200 p-4 rounded-lg my-4 grid grid-cols-1">
                    Any Moonbirds you've listed or are listed to you for purchase will appear here.
                </div>
            </>
        );
    }

    return (
        <>
            <div className="text-2xl">Active listings</div>
            <div className="bg-slate-200 p-4 rounded-lg my-4">
                <div className="grid lg:grid-cols-4 gap-4 md:grid-cols-3">
                    {listings.map(renderActiveListing)}
                </div>
            </div>
        </>
    );
}

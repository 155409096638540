import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@rainbow-me/rainbowkit/styles.css';
import {
    getDefaultWallets,
    RainbowKitProvider,
} from '@rainbow-me/rainbowkit';
import {
    chain,
    configureChains,
    createClient,
    WagmiConfig,
} from 'wagmi';

import { publicProvider } from 'wagmi/providers/public';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { infuraProvider } from 'wagmi/providers/infura';

import { Network, Alchemy } from "alchemy-sdk";

const MOONBIRDS_CONTRACT_ABI = require('./contractAbis/Moonbirds.json');
const BIRDSWAP_CONTRACT_ABI = require('./contractAbis/BirdSwap.json');


const { REACT_APP_CHAIN_ID,
    REACT_APP_ALCHEMY_API_KEY,
    REACT_APP_INFURA_API_KEY,
    REACT_APP_MOONBIRDS_CONTRACT_ADDRESS,
    REACT_APP_BIRDSWAP_CONTRACT_ADDRESS,
    REACT_APP_MOONBIRDS_ROYALTY_FEE_BPS,
    REACT_APP_BIRDSWAP_SERVICE_FEE_BPS
} = process.env;

const appChain = Object.values(chain).find(c => c.id === parseInt(REACT_APP_CHAIN_ID));

const { chains, provider } = configureChains(
    [appChain],
    [
        publicProvider({priority: 2}),
        infuraProvider({apiKey: REACT_APP_INFURA_API_KEY, priority: 1}),
        alchemyProvider({ apiKey: REACT_APP_ALCHEMY_API_KEY, priority: 0 })
    ]
);

const { connectors } = getDefaultWallets({
    appName: 'NestedTrade',
    chains
});

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider
});

const alchemyClient = new Alchemy({
    apiKey: REACT_APP_ALCHEMY_API_KEY,
    network: parseInt(REACT_APP_CHAIN_ID) === chain.mainnet.id ? Network.ETH_MAINNET : Network.ETH_GOERLI
});
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
      <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider chains={chains}>
            <App
                moonbirdsContractAddress={REACT_APP_MOONBIRDS_CONTRACT_ADDRESS}
                moonbirdsContractAbi={MOONBIRDS_CONTRACT_ABI}
                birdswapContractAddress={REACT_APP_BIRDSWAP_CONTRACT_ADDRESS}
                birdswapContractAbi={BIRDSWAP_CONTRACT_ABI}
                moonbirdsRoyaltyFeeBps={REACT_APP_MOONBIRDS_ROYALTY_FEE_BPS}
                birdswapServiceFeeBps={REACT_APP_BIRDSWAP_SERVICE_FEE_BPS}
                alchemyClient={alchemyClient}
                network={appChain}
            />
          </RainbowKitProvider>
      </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

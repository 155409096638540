
const BURN_ADDRESS = '0x0000000000000000000000000000000000000000';

const isBirdListed = (ask) => {
  return ask.seller && ask.seller !== BURN_ADDRESS;
};

const addressEquals = (a1, a2) => {
    if (a1 && a2) {
        return a1.toLowerCase() === a2.toLowerCase();
    }
    return false;
};

const getMoonbirdImgSrc = (tokenId) => {
    return `https://live---metadata-5covpqijaa-uc.a.run.app/images/${tokenId}`;
};

const getNestingDurationInDays = (nestingData) => {
    const seconds = nestingData.current.toNumber();
    return Math.floor(seconds / 86400);
};

const getLatestAsksFromAskCreatedEvents = (askCreatedEvents) => {
    return askCreatedEvents.reduce((acc, val) => {
        const tokenId = val.args.tokenId.toNumber();
        if (isNaN(acc[tokenId])) {
            acc[tokenId] = val;
        }

        if (acc[tokenId].blockNumber < val.blockNumber) {
            acc[tokenId] = val;
        }
        return acc;
    }, {});
};

function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
}


function timeSinceShort(date) {

    var seconds = Math.floor((new Date() - date) / 1000);

    var interval = seconds / 31536000;

    if (interval > 1) {
        return Math.floor(interval) + "y";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
        return Math.floor(interval) + "mo";
    }
    interval = seconds / 86400;
    if (interval > 1) {
        return Math.floor(interval) + "d";
    }
    interval = seconds / 3600;
    if (interval > 1) {
        return Math.floor(interval) + "h";
    }
    interval = seconds / 60;
    if (interval > 1) {
        return Math.floor(interval) + "min";
    }
    return Math.floor(seconds) + "sec";
}

export default {
    BURN_ADDRESS,
    isBirdListed,
    addressEquals,
    getMoonbirdImgSrc,
    getNestingDurationInDays,
    getLatestAsksFromAskCreatedEvents,
    FAQ_URI: 'https://unleashed-galaxy-ac1.notion.site/Birdswap-FAQ-14555efc2cb74e3394a559b4ca7f63de',
    timeSince,
    timeSinceShort
}

import React from "react";

export default function MetadataTable(props) {
    const { tokenMetadata } = props;

    function renderAttributeRow(row, i) {
        return (
            <tr key={i}>
                <td className="border border-slate-300 p-2">{row.trait_type}</td>
                <td className="border border-slate-300 p-2">{row.value}</td>
            </tr>
        )
    }
    return (
        <table className="table-auto border-separate border-spacing-2 border border-slate-400">
            <thead>
            <tr>
                <th className="border border-slate-300 p-2">Trait</th>
                <th className="border border-slate-300 p-2">Value</th>
            </tr>
            </thead>
            <tbody>
            {tokenMetadata.map(renderAttributeRow)}
            </tbody>
        </table>
    );
}

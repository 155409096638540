import {Link, useParams} from "react-router-dom";
import MarketplaceInlineMenu from "../components/MarketplaceInlineMenu";
import {useAccount, useContractReads, useContractWrite, usePrepareContractWrite, useWaitForTransaction} from "wagmi";
import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Alert from 'react-bootstrap/Alert';
import utils from '../Utils';
import { ethers } from "ethers";
import MetadataTable from "../components/MetadataTable";
import {Spinner} from "react-bootstrap";
import toast from 'react-hot-toast';



function ItemDetails(props) {
    const { tokenId } = useParams();
    const {
        alchemyClient,
        moonbirdsContractAddress,
        moonbirdsContractAbi,
        birdswapContractAddress,
        birdswapContractAbi
    } = props;

    const birdswapContract = {
        addressOrName: birdswapContractAddress,
        contractInterface: birdswapContractAbi
    };

    const { data: birdswapContractData, isError: birdswapContractError, isLoading: isBirdSwapContractDataLoading } = useContractReads({
        contracts: [
            {
                ...birdswapContract,
                functionName: 'askForMoonbird',
                args: [tokenId],
                cacheOnBlock: true
            },
            {
                ...birdswapContract,
                functionName: 'isMoonbirdEscrowed',
                args: [tokenId],
                cacheOnBlock: true
            },
            {
                ...birdswapContract,
                functionName: 'moonbirdTransferredFromOwner',
                args: [tokenId],
                cacheOnBlock: true
            },
            {
                addressOrName: moonbirdsContractAddress,
                contractInterface: moonbirdsContractAbi,
                functionName: 'nestingPeriod',
                args: [tokenId],
                cacheOnBlock: true
            }
        ]
    });

    const [tokenOwner, setTokenOwner] = useState(null);
    const [tokenMetadata, setTokenMetadata] = useState([]);
    const [isListed, setIsListed] = useState(false);
    const [ask, setAsk] = useState({});
    const [isBirdEscrowed, setIsBirdEscrowed] = useState(false);
    const [nestingPeriodData, setNestingPeriodData] = useState(null);


    const { address, isConnected } = useAccount();

    const { config: cancelAskConfig } = usePrepareContractWrite({
        ...birdswapContract,
        functionName: 'cancelAsk',
        args: [tokenId],
        enabled: utils.addressEquals(ask?.seller, address) && isListed && isBirdEscrowed
    });
    const { data: cancelAskData, isLoading: isCancelAskTxInputLoading, writeAsync: writeCancelAsk } = useContractWrite({
        ...cancelAskConfig,
        onError(error) {
            console.log(error, error.code, error.message);
            if (error.code === 'ACTION_REJECTED') {
                toast.error('You rejected the transaction.');
            } else {
                toast.error(error.message || 'Unexpected error occurred');
            }
        }
    });

    const { data: cancelAskCompleteData, error: cancelAskError, isError: isCancelAskError, isLoading: isCancelAskLoading, isSuccess: isCancelAskSuccess } = useWaitForTransaction({
        hash: cancelAskData?.hash,
    });

    const { config: fillAskConfig } = usePrepareContractWrite({
        ...birdswapContract,
        functionName: 'fillAsk',
        args: [tokenId],
        overrides: {
            value: ask?.askPrice,
        },
        enabled: utils.addressEquals(ask?.buyer, address) && isBirdEscrowed
    });
    const { data: fillAskData, isLoading: isFillAskTxInputLoading, writeAsync: writeFillAsk } = useContractWrite({
        ...fillAskConfig,
        onError(error) {
            console.log(error, error.code, error.message);
            if (error.code === 'ACTION_REJECTED') {
                toast.error('You rejected the transaction.');
            } else {
                toast.error(error.message || 'Unexpected error occurred');
            }
        }
    });

    const { data: fillAskCompleteData, error: fillAskError, isError: isFillAskError, isLoading: isFillAskLoading, isSuccess: isFillAskSuccess } = useWaitForTransaction({
        hash: fillAskData?.hash,
    });


    useEffect( () => {
        async function fetchData() {
            try {
                const resp = await alchemyClient.nft.getOwnersForNft(moonbirdsContractAddress, tokenId);
                const owner = resp.owners[0];
                // bird is not currently in escrow
                if (!utils.addressEquals(owner, birdswapContractAddress)) {
                    setTokenOwner(resp.owners[0]);
                }
            } catch (error) {
                console.error('Failed to fetch NFT data for token ' + tokenId, error);
            }
            try {
                const resp = await alchemyClient.nft.getNftMetadata(moonbirdsContractAddress, tokenId);
                //setTokenImgUri(resp.media.thumbnail);
                setTokenMetadata(resp.rawMetadata.attributes)
            } catch (error) {
                console.error('Failed to fetch NFT metadata for token ' + tokenId, error);
            }
        }
        fetchData();
    }, [tokenId]);

    useEffect(() => {
        console.log('Contract data: ', birdswapContractData);
        if (!isBirdSwapContractDataLoading && Object.keys(ask.length === 0)) {
            const askForMoonbirdData = birdswapContractData[0];
            const isMoonbirdEscrowed = birdswapContractData[1];
            if (utils.isBirdListed(askForMoonbirdData)) {
                setIsListed(true);
                setAsk(askForMoonbirdData);
            } else {
                setIsListed(false);
            }
            setIsBirdEscrowed(isMoonbirdEscrowed);

            if (isMoonbirdEscrowed) {
                const originalOwner = birdswapContractData[2];
                setTokenOwner(originalOwner);
            }
            setNestingPeriodData(birdswapContractData[3]);
        }

    }, [isBirdSwapContractDataLoading]);

    useEffect(() => {
        if (isCancelAskSuccess) {
            setAsk({
                buyer: "0x0000000000000000000000000000000000000000",
                seller: "0x0000000000000000000000000000000000000000",
                uid: "0x0000000000000000000000000000000000000000",
                price: ethers.BigNumber.from('0')
            });
            setIsListed(false);
            toast.success('Listing successfully cancelled!', { duration: 10000 });
        }
    }, [isCancelAskSuccess]);
    useEffect(() => {
        if (isFillAskSuccess) {
            setAsk({
                buyer: "0x0000000000000000000000000000000000000000",
                seller: "0x0000000000000000000000000000000000000000",
                uid: "0x0000000000000000000000000000000000000000",
                price: ethers.BigNumber.from('0')
            });
            setIsListed(false);
            setIsBirdEscrowed(false);
            setTokenOwner(address);
            toast.success(`Successfully purchased Moonbird #${tokenId}!`, { duration: 60000 });
        }
    }, [isFillAskSuccess]);
    useEffect(() => {
        if (isCancelAskError) {
            toast.error('Error canceling ask: ' + cancelAskError.message);
            console.error(cancelAskError);
        } else if (isFillAskError) {
            toast.error('Error canceling ask: ' + fillAskError.message);
            console.error(fillAskError);
        }
    }, [isCancelAskError, isFillAskError]);



    async function fillAsk() {
        try {
            const txResp = await writeFillAsk();
            toast((t) => (
                <span>
                    Your transaction has been submitted to the network. Track its progress <a target="_blank" href={`https://etherscan.io/tx/${txResp?.hash}`}>here</a>.
                  </span>
            ), { duration: 12000 });
        } catch (error) {
            console.error('Failed to fill ask for token ID: ' + tokenId, error);
        }
    }

    async function cancelAsk() {
        try {
            const txResp = await writeCancelAsk();
            toast((t) => (
                <span>
                    Your transaction has been submitted to the network. Track its progress <a target="_blank" href={`https://etherscan.io/tx/${txResp?.hash}`}>here</a>.
                  </span>
            ), { duration: 12000 });
        } catch (error) {
            console.error('Failed to cancel ask for token ID: ' + tokenId, error);
        }
    }


    function truncate(str, n){
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    }

    function renderCallToAction() {
        let callToActionText = '';
        let callToActionNavLink = '';
        let onClick = () => {};
        let disabled = false;
        let showLoadingSpinner = false;
        let secondButton = null;

        if (Object.keys(ask.length === 0) && (isBirdSwapContractDataLoading || birdswapContractError)) {
            return null;
        }

        // Bird is owned by user and not listed
        if (utils.addressEquals(address, tokenOwner) && !utils.isBirdListed(ask)) {
            callToActionText = `Create listing`;
            callToActionNavLink = `/bird/${tokenId}/create-listing`;
        // bird is owned by user and listed
        } else if (utils.addressEquals(address, tokenOwner) && utils.isBirdListed(ask)) {
            callToActionText = `Cancel listing and withdraw bird`;
            if (isCancelAskTxInputLoading) {
                callToActionText = 'Waiting for approval...';
            } else if (isCancelAskLoading) {
                callToActionText = 'Confirming your transaction...';
            }
            onClick = cancelAsk;
            disabled = !writeCancelAsk || isCancelAskTxInputLoading || isCancelAskLoading || isCancelAskSuccess;
            showLoadingSpinner = isCancelAskTxInputLoading || isCancelAskLoading;
            /*
            secondButton = (
                <Link to={`/bird/${tokenId}/update-listing/${ask?.uid}`}>
                    <Button variant="primary" className="ml-2">
                        Update Listing
                    </Button>
                    {secondButton}
                </Link>
            );*/
        }
        // bird is not owned by user and user is listed as buyer and bird is escrowed
        else if (!utils.addressEquals(address, tokenOwner) && utils.addressEquals(address, ask.buyer) && isBirdEscrowed) {
            callToActionText = `Purchase for ${ethers.utils.formatEther(ask.askPrice)} ETH`;
            if (isFillAskTxInputLoading) {
                callToActionText = 'Waiting for approval...';
            } else if (isFillAskLoading) {
                callToActionText = 'Confirming your transaction...';
            }
            onClick = fillAsk;
            disabled = !writeFillAsk || isFillAskTxInputLoading || isFillAskLoading || isFillAskSuccess;
            showLoadingSpinner = isFillAskTxInputLoading || isFillAskLoading;

            // bird is not owned by user and user is listed as buyer and bird is npt escrowed
        } else if (!utils.addressEquals(address, tokenOwner) && utils.addressEquals(address, ask.buyer) && !isBirdEscrowed) {
            callToActionText = `Seller has not moved NFT to escrow yet`;
        }
        else {
            // You are neither the seller nor the buyer
            return null;
        }
        return (
            <div className="mt-4">
                <Link to={callToActionNavLink}>
                    <Button variant="primary" onClick={onClick} disabled={disabled}>
                        {showLoadingSpinner && <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />}
                        {callToActionText}
                    </Button>
                </Link>
                {secondButton}
            </div>
        );
    }

    function renderNestingPeriodElement() {
        let nestingElement = null;

        if (nestingPeriodData?.current && nestingPeriodData?.nesting === true) {
            const daysNested = utils.getNestingDurationInDays(nestingPeriodData);
            if (daysNested > 0) {
                nestingElement = <div className="my-2">{`Days nested: ${daysNested}`}</div>;
            } else {
                nestingElement = <div className="my-2">{`Days nested: < 1`}</div>;
            }
        } else if (nestingPeriodData?.nesting === false) {
            nestingElement = <div className="my-2">Unnested</div>;
        }
        return nestingElement;
    }

    const ownerDisplayText = utils.addressEquals(address, tokenOwner) ? "You" : truncate(tokenOwner || '...', 16);


    let priceElement = null;

    if (isListed) {
        priceElement = (
            <div className="my-2 flex flex-row">
                <div>{`Price:`}</div>
                &nbsp;
                <div className="font-extrabold">
                    {`${ethers.utils.formatEther(ask.askPrice)} ETH`}
                </div>
            </div>
        );
    }

    const privateListingDisplayAlert = isListed ? (
        <Alert variant="primary">{`This is a private listing reserved for ${ask.buyer}`}</Alert>
    ) : null;

    let listedWithoutEscrowErrorAlert = null;
    if (isListed && !isBirdEscrowed) {
        if (utils.addressEquals(address, ask?.seller)) {
            listedWithoutEscrowErrorAlert = (
                <Alert variant="warning">
                    Your listing process was interrupted before it was finalized. Please resubmit your listing <Link to={`/bird/${tokenId}/create-listing`}>here</Link>.
                </Alert>
            );
        } else {
            listedWithoutEscrowErrorAlert = <Alert variant="warning">{`This listing is pending the NFT being transferred to the escrow by the owner`}</Alert>;
        }
    }

    return (
        <div className="m-4">
            {privateListingDisplayAlert}
            {listedWithoutEscrowErrorAlert}
            <div className="flex flex-row flex-wrap">
                <div className="basis-full md:basis-1/2 mt-4">
                    <img className="rounded-lg" src={utils.getMoonbirdImgSrc(tokenId)} alt={`Image for bird #${tokenId}`} height={300} width={300}/>
                </div>
                <div className="basis-full md:basis-1/2 mt-4">
                    <div className="text-2xl">{`Moonbird #${tokenId}`}</div>
                    <div className="my-2 flex flex-row">
                        <div>{`Owned by:`}</div>
                        &nbsp;
                        <div>
                            <a target="_blank" href={`https://etherscan.io/address/${tokenOwner}`}>
                            {ownerDisplayText}
                            </a>
                        </div>
                    </div>
                    {priceElement}
                    {renderNestingPeriodElement()}
                    {/*<div className="mt-2 mb-4">{`Nesting tier: Silver`}</div>*/}
                    <MarketplaceInlineMenu tokenId={tokenId}/>
                    {renderCallToAction()}
                </div>
            </div>
            <div className="flex flex-row flex-wrap mt-4 border-t-2">
                <div className="text-2xl basis-full my-4">{`Properties`}</div>
                <div className="basis-full md:basis-1/2">
                    <MetadataTable tokenMetadata={tokenMetadata}/>
                </div>
            </div>
        </div>
    );
}

export default ItemDetails;

import './App.css';

import {
    Routes,
    Route, HashRouter,
} from "react-router-dom";

import Header from "./components/Header";
import CreateListing from "./pages/CreateListing";
import Home from "./pages/Home";
import ItemDetails from "./pages/ItemDetails";
import { Toaster } from 'react-hot-toast';

function App(props) {
  return (
    <div>
        <div style={{'minHeight' : 'calc(100vh - 50px)'}}>
          <HashRouter forceRefresh={true}>
            <Header />
            <Toaster position="top-right"
                     reverseOrder={false} />
            <Routes>
              <Route path="/" element={<Home {...props} />}/>
              <Route path="/about" element={<div>About</div>}/>
              <Route path="/faq" element={<div>FAQ</div>}/>
              <Route path="bird/:tokenId" element={<ItemDetails {...props} />} key={Date.now()}/>
              <Route path="bird/:tokenId/create-listing" element={<CreateListing {...props} />} />
              {/*<Route path="bird/:tokenId/update-listing/:askId" element={<UpdateListing {...props} />} />*/}

            </Routes>
          </HashRouter>
        </div>
      <footer className="p-4 bg-white rounded-lg shadow w-full md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
        <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
                  Open source code maintained by&nbsp;<a target="_blank" href="https://twitter.com/montana_wong">@Montana_Wong</a> &nbsp;and&nbsp; <a target="_blank"  href="https://twitter.com/fabdarice">@fabdaRice</a>
        </span>
      </footer>
    </div>
  );
}

export default App;

import moonbirdsLogo from "../images/moonbirds.png";
import openseaLogo from "../images/opensea.svg";
import looksrareLogo from "../images/looksrare-black.svg";
import x2y2Logo from "../images/x2y2.svg";


function MarketplaceInlineMenu(props) {

    const {tokenId} = props;

    return (
        <div className="flex justify-start">
            <a href={`https://nest.moonbirds.xyz/moonbirds/${tokenId}`} target="_blank">
                <img src={moonbirdsLogo} height={25} width={25} />
            </a>
            <a href={`https://opensea.io/assets/ethereum/0x23581767a106ae21c074b2276d25e5c3e136a68b/${tokenId}`} target="_blank">
                <img className="ml-2" src={openseaLogo} height={25} width={25} />
            </a>
            <a href={`https://looksrare.org/collections/0x23581767a106ae21c074b2276D25e5C3e136a68b/${tokenId}`} target="_blank">
                <img className="mx-2" src={looksrareLogo} height={25} width={25} />
            </a>
            <a href={`https://x2y2.io/eth/0x23581767a106ae21c074b2276D25e5C3e136a68b/${tokenId}`} target="_blank">
                <img src={x2y2Logo} height={25} width={25} />
            </a>
        </div>
    );
}
export default MarketplaceInlineMenu;

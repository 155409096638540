import React, {useEffect, useState} from "react";
import BirdGridItemCard from "../components/BirdGridItemCard";
import {useAccount, useContract, useContractRead} from "wagmi";
import utils from "../Utils";
import ActiveListings from "../components/ActiveListings";
import PreviousSales from "../components/PreviousSales";
import CountUp from "react-countup";

function Home(props) {
    const {
        alchemyClient,
        moonbirdsContractAddress,
        network,
        birdswapContractAddress,
        birdswapContractAbi
    } = props;

    const { address, isConnected } = useAccount();

    const [ownedMoonbirds, setOwnedMoonbirds] = useState([]);

    const { data: totalSwapBigNumber, isError: birdswapContractError, isLoading: isBirdSwapContractDataLoading } = useContractRead({
        addressOrName: birdswapContractAddress,
        contractInterface: birdswapContractAbi,
        functionName: 'totalSwap',
        cacheOnBlock: true
    });

    useEffect( () => {
        if (address) {
            async function fetchData() {
                try {
                    const resp = await alchemyClient.nft.getNftsForOwner(address, {contractAddresses: [moonbirdsContractAddress]});
                    //console.log(resp.ownedNfts);
                    setOwnedMoonbirds(resp.ownedNfts);
                } catch (error) {
                    console.error('Failed to fetch moonbird nfts for user ', error);
                }
            }
            fetchData();
        }
    }, [address]);


    const renderBirdsToList = (ownedBird, i) => {
        return <BirdGridItemCard
            key={i}
            imgSrc={utils.getMoonbirdImgSrc(ownedBird.tokenId)}
            tokenId={ownedBird.tokenId}
            ctaButtonText="Create listing"
            onCTAButtonClick={() =>{}}
            owner={address}
            navigateTo={`/bird/${ownedBird.tokenId}/create-listing`}
            network={network}
        />;
    };

    const renderUserBirdsSection = () => {
        if (ownedMoonbirds.length === 0) {
            return null;
        }

        return (
            <>
                <div className="text-2xl mt-4">Select birds to list</div>
                <div className="bg-slate-200 p-4 rounded-lg my-4">
                    <div className="grid lg:grid-cols-4 gap-4 md:grid-cols-3">
                        {ownedMoonbirds.map(renderBirdsToList)}
                    </div>
                </div>
            </>
        )
    };

    return (
        <div className="m-4">
            <section className="mt-24 mx-auto max-w-screen-xl pb-4 px-4 sm:px-8">
                <div className="text-center space-y-4">
                    <h1 className="text-gray-800 font-bold text-4xl md:text-5xl">
                        Buy and sell nested
                        <span className="text-indigo-600"> Moonbird NFTs</span>
                    </h1>
                    <p className="text-gray-500 max-w-xl mx-auto leading-relaxed">
                        NestedTrade is a decentralized protocol for buying and selling Moonbird NFTs, while keeping them nested.
                    </p>
                </div>
                <div className="mt-12 justify-center items-center space-y-3 sm:space-x-6 sm:space-y-0 sm:flex">
                    <a href={utils.FAQ_URI} target="_blank" className="px-10 py-3.5 w-full text-gray-500 text-center border rounded-md duration-300 text-white bg-indigo-600 hover:bg-indigo-700 hover:shadow block sm:w-auto">
                        Learn more
                    </a>
                </div>
            </section>
            <ActiveListings {...props} />
            {renderUserBirdsSection()}
            <div className="text-2xl my-4">Recently sold birds</div>
            {!isBirdSwapContractDataLoading && totalSwapBigNumber &&
            <div className="grid  my-4">
                <CountUp
                    prefix="Total trades: "
                    enableScrollSpy={true}
                    end={totalSwapBigNumber.toNumber()} />
            </div>
            }
            <PreviousSales {...props} />

        </div>
    );
}

export default Home;

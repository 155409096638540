import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Link } from "react-router-dom";
import utils from "../Utils";


function Header() {

    return (
        <div className="flex place-content-between m-4">
            <div className="flex flex-row sm:flex-col gap-x-10 items-center">
                <Link to="/" style={{ textDecoration: 'none' }}>
                    <div className="text-3xl font-bold text-indigo-600">NestedTrade</div>
                </Link>
                <a href={utils.FAQ_URI} target="_blank" className="hidden sm:block">
                    <div className="basis-1/4">FAQ</div>
                </a>
            </div>
            <ConnectButton />
        </div>
    )
};

export default Header;

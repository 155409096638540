import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import classNames from "classnames";
import {useAccount} from "wagmi";
import {Spinner} from "react-bootstrap";
import {Link} from "react-router-dom";
import toast from "react-hot-toast";

function CreateListingModal(props) {
    const {
        writeCreateAsk,
        createAskSuccess,
        isCreateAskTxInputLoading,
        isCreateAskTxLoading,
        writeSafeTransferWhileNesting,
        safeTransferWhileNestingSuccess,
        isSafeTransferWhileNestingInputLoading,
        isSafeTransferWhileNestingLoading,
        price,
        royaltyBps,
        tokenId,
        title,
        skipTransfer,
        serviceFeeBps
    } = props;

    const { address, isConnected } = useAccount();

    const doWriteCreateAsk = async () => {
        try {
            const txResp = await writeCreateAsk();
            toast((t) => (
                <span>
                    Your transaction has been submitted to the network. Track its progress <a target="_blank" href={`https://etherscan.io/tx/${txResp?.hash}`}>here</a>.
                  </span>
            ), { duration: 12000 });
        } catch (error) {
            console.error('Failed to Create Ask', error);
        }
    };

    const doWriteSafeTransferWhileNesting = async () => {
        try {
            const txResp = await writeSafeTransferWhileNesting();
            toast((t) => (
                <span>
                    Your transaction has been submitted to the network. Track its progress <a target="_blank" href={`https://etherscan.io/tx/${txResp?.hash}`}>here</a>.
                  </span>
            ), { duration: 12000 });
        } catch (error) {
            console.error('Failed to Transfer bird to escrow', error);
        }
    };


    let step1ButtonText = 'Continue';
    if (isCreateAskTxInputLoading) {
        step1ButtonText = 'Waiting for approval...';
    } else if (isCreateAskTxLoading) {
        step1ButtonText = 'Waiting for transaction to confirm...';
    } else if (createAskSuccess) {
        step1ButtonText = 'Transaction successful!'
    }

    let step2ButtonText = 'Transfer';
    if (isSafeTransferWhileNestingInputLoading) {
        step2ButtonText = 'Waiting for approval...';
    } else if (isSafeTransferWhileNestingLoading) {
        step2ButtonText = 'Waiting for transaction to confirm...';
    } else if (safeTransferWhileNestingSuccess) {
        step2ButtonText = 'Transfer successful!'
    }

    let step1Open = !createAskSuccess;
    let step2Open = createAskSuccess && !safeTransferWhileNestingSuccess;


    return (
        <>
            <Modal show={props.show} onHide={props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <div className="border-b">
                    <div className="m-3">
                        <div className="mb-3 text-lg">Summary</div>
                        <div className="bg-slate-200 p-4 rounded-lg">
                            <div className="flex justify-between">
                                <div className="">List price</div>
                                <div className="">{`${price} ETH`}</div>
                            </div>
                            <div className="flex justify-between">
                                <div className="">Creator Royalty</div>
                                <div className="">{`${royaltyBps / 100} %`}</div>
                            </div>
                            <div className="flex justify-between">
                                <div className="">Service fee</div>
                                <div className="">{`${serviceFeeBps / 100} %`}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal.Body>
                    <>
                        <div className="text-2xl my-4">Step 1: Confirm listing</div>
                        <div className={classNames({ hidden : !step1Open })}>
                            <div className="text-sm my-4">You will be prompted to create this listing from your wallet. Please do not leave or refresh the page, until instructed to do so.</div>
                            <Button variant="primary" onClick={() => doWriteCreateAsk?.()} disabled={!writeCreateAsk || isCreateAskTxInputLoading || isCreateAskTxLoading}>
                                {(isCreateAskTxInputLoading || isCreateAskTxLoading) && <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}
                                {step1ButtonText}
                            </Button>
                        </div>
                    </>
                    <div className={classNames({ hidden: skipTransfer })}>
                        <div className="text-2xl my-4">Step 2: Transfer Moonbird NFT to escrow</div>
                        <div className={classNames({ hidden : !step2Open })}>
                            <div className="text-sm my-4">You will be prompted to transfer your nested bird to the Birdswap escrow. This is required in order for your listing to be complete and will not unnest your bird. You may withdraw your Moonbird NFT back into your wallet at anytime before it is purchased.</div>
                            <Button variant="primary " onClick={() => doWriteSafeTransferWhileNesting?.()}
                                    disabled={!writeSafeTransferWhileNesting || isSafeTransferWhileNestingInputLoading || isSafeTransferWhileNestingLoading}
                                >
                                {(isSafeTransferWhileNestingInputLoading || isSafeTransferWhileNestingLoading) && <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />}
                                {step2ButtonText}
                            </Button>
                        </div>
                    </div>
                    {((createAskSuccess && safeTransferWhileNestingSuccess) || (createAskSuccess && skipTransfer))&& <Link to={`/bird/${tokenId}`}>
                        <Button variant="primary">
                            View your listing!
                        </Button>
                    </Link>}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default CreateListingModal;

import React, {useEffect, useState} from "react";
import {useContract, useProvider} from "wagmi";
import toast from "react-hot-toast";
import utils from "../Utils";
import { etherscanBlockExplorers } from 'wagmi'
import Button from "react-bootstrap/Button";
import { useBlockNumber } from 'wagmi'
import { ethers } from "ethers";
import {Link} from "react-router-dom";


export default function PreviousSales(props) {
    const {
        birdswapContractAddress,
        birdswapContractAbi
    } = props;

    const [sales, setSales] = useState([]);

    const provider = useProvider();

    const contract = useContract({
        addressOrName: birdswapContractAddress,
        contractInterface: birdswapContractAbi,
        signerOrProvider: provider
    });

    const { data: currentBlockNumber, isError, isLoading } = useBlockNumber();


    useEffect(() => {
        if (contract) {
            const fetchData = async () => {

                try {
                    const filter = contract.filters.AskFilled();
                    const sales = await contract.queryFilter(filter);
                    //console.log('listings', sales);
                    setSales(sales);
                } catch (error) {
                    console.error('Failed to fetch previous sales.', error);
                    toast.error('Failed to fetch previous sales.');
                }


            };
            fetchData();
        }
    }, [contract]);


    function truncate(str, n){
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    }

    function getEstimatedDateAtBlockNumber(blockNumber) {
        const now = new Date();
        if (isNaN(currentBlockNumber) || isLoading || isError) {
            return now;
        }
        const blockNumberDelta = currentBlockNumber - blockNumber;
        // assuming 12 seconds per block post-merge
        const blockNumberDeltaMilliseconds = blockNumberDelta * 12 * 1000;
        const estimatedDate = new Date(Date.now() - blockNumberDeltaMilliseconds);
        if (estimatedDate > now) {
            // if for some reason estiamted date is greater than current time, just return current time.
            return now;
        }
        return estimatedDate;
    }

    const renderRow = (listing, i) => {
        let innerColComponent = (
            <>
                <td className="py-4 px-6 hidden sm:table-cell">
                    <a href={`${props.network.blockExplorers.default.url}/address/${listing?.args?.seller}`} target="_blank">
                        {truncate(listing?.args?.seller, 8)}
                    </a>
                </td>
                <td className="py-4 px-6 hidden sm:table-cell">
                    <a href={`${props.network.blockExplorers.default.url}/address/${listing?.args?.buyer}`} target="_blank">
                        {truncate(listing?.args?.buyer, 8)}
                    </a>
                </td>
            </>
        );
        if (!isNaN(currentBlockNumber) && !isLoading && !isError) {
            innerColComponent = (
                <td className="py-4 px-6 hidden sm:table-cell">
                    {`${utils.timeSinceShort(getEstimatedDateAtBlockNumber(listing.blockNumber))} ago`}
                </td>
            );
        }

        return (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700" key={i}>
                <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <div className="flex items-center">
                        <Link to={`/bird/${listing?.args?.tokenId}`} style={{'textDecoration': 'none', 'color': 'black'}}>
                            <img className="rounded-lg" src={utils.getMoonbirdImgSrc(listing?.args?.tokenId)} alt={`Image for bird #${listing?.args?.tokenId}`} height={50} width={50}/>
                        </Link>
                        <Link to={`/bird/${listing?.args?.tokenId}`} style={{'textDecoration': 'none', 'color': 'black'}}>

                            <span className="ml-2">
                                {`Moonbird #${listing?.args?.tokenId}`}
                            </span>
                        </Link>

                    </div>
                </td>
                <td className="py-4 px-6">{`${ethers.utils.formatEther(listing?.args?.askPrice)} ETH`}</td>
                {innerColComponent}
                <td className="py-4 px-6 hidden sm:table-cell">
                    <a href={`${props.network.blockExplorers.default.url}/tx/${listing?.transactionHash}`} target="_blank" style={{'textDecoration': 'none'}}>
                        <button className="px-4 py-2 w-full text-gray-500 text-center border rounded-md duration-300 text-white bg-indigo-600 hover:bg-indigo-700 hover:shadow block sm:w-auto">View transaction</button>
                    </a>
                </td>
            </tr>
        );
    };

    const rowHeaders = [
        <th scope="col" className="py-3 px-6">Item</th>,
        <th scope="col" className="py-3 px-6">Price</th>
    ];

    if (!isNaN(currentBlockNumber) && !isLoading && !isError) {
        // use time header
        rowHeaders.push(
            <th scope="col" className="py-3 px-6 hidden sm:table-cell">Time</th>
        );
    } else {
        // use from and to headers as fallback
        rowHeaders.push(...[
            <th scope="col" className="py-3 px-6 hidden sm:table-cell">From</th>,
            <th scope="col" className="py-3 px-6 hidden sm:table-cell">To</th>
        ])
    }
    rowHeaders.push(
        <th scope="col" className="py-3 px-6 hidden sm:table-cell">Transaction</th>
    );

        return (
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                {rowHeaders}
            </tr>
            </thead>
            <tbody>
                {sales.sort((a, b) => b.blockNumber - a.blockNumber).map(renderRow)}
            </tbody>
        </table>
    );

}

import Card from "react-bootstrap/Card";
import MarketplaceInlineMenu from "./MarketplaceInlineMenu";
import Button from "react-bootstrap/Button";
import React from "react";
import { ethers } from "ethers";
import {useAccount} from "wagmi";
import { Link } from "react-router-dom";
import utils from '../Utils';
import classNames from "classnames";


function BirdGridItemCard(props) {
    const {
        salePriceWei,
        imgSrc,
        tokenId,
        onCTAButtonClick,
        ctaButtonText,
        owner,
        navigateTo,
        network,
        buyer // optional
    } = props;

    const { address, isConnected } = useAccount();

    function truncate(str, n){
        return (str.length > n) ? str.slice(0, n-1) + '...' : str;
    };

    function renderPrice(salePriceWei) {
        if (!isNaN(salePriceWei)) {
            return (
                <div className="flex flex-row">
                    <div>
                        Price:
                    </div>
                    <div className="ml-2">
                        {`${ethers.utils.formatEther(salePriceWei)} ETH`}
                    </div>
                </div>
            );
        }
        return null;
    };

    const ownerDisplayText = utils.addressEquals(address, owner) ? "You" : truncate(owner ? owner : '', 16);
    let buyerDisplayElement = null;

    if (buyer) {
        let buyerDisplayText = 'You';
        if (!utils.addressEquals(buyer, address)) {
            buyerDisplayText = truncate(buyer ? buyer : '', 16)
        }
        buyerDisplayElement = (
            <div className="mb-2 text-xs">
                <>
                    {'Listed for: '}
                    <a target="_blank" href={`${network.blockExplorers.default.url}/address/${buyer}`}>{buyerDisplayText}</a>
                </>
            </div>
        );
    }

    let ctaLinkWrapper = (
        <Link to={`${navigateTo}`} style={{'textDecoration': 'none'}}>
            <button  className="px-5 py-2 w-full text-gray-500 text-center border rounded-md duration-300 text-white bg-indigo-600 hover:bg-indigo-700 hover:shadow block sm:w-auto" onClick={onCTAButtonClick}>
                {ctaButtonText}
            </button>
        </Link>
    );

    if (props.externalLink) {
        ctaLinkWrapper = (
            <a href={`${navigateTo}`} target="_blank" style={{'textDecoration': 'none'}}>
                <button  className="px-5 py-2 w-full text-gray-500 text-center border rounded-md duration-300 text-white bg-indigo-600 hover:bg-indigo-700 hover:shadow block sm:w-auto" onClick={onCTAButtonClick}>
                    {ctaButtonText}
                </button>
            </a>
        );
    }

    return (
        <Card>
            <Link to={`/bird/${tokenId}`}>
                <Card.Img variant="top" src={imgSrc} />
            </Link>
            <Card.Body>
                <Card.Title className="mb-2">{`Moonbird #${tokenId}`}</Card.Title>
                <div className={classNames('text-xs', {'mb-2' :  buyerDisplayElement === null})}>
                    {renderPrice(salePriceWei)}
                    <>
                        {'Owner: '}
                        <a target="_blank" href={`${network.blockExplorers.default.url}/address/${owner}`}>{ownerDisplayText}</a>
                    </>
                </div>
                {buyerDisplayElement}
                <MarketplaceInlineMenu tokenId={tokenId} />
            </Card.Body>
            <div className="ml-4 mb-2">
                {ctaLinkWrapper}
            </div>
        </Card>
    );
}

export default BirdGridItemCard;

import {useNavigate, useParams} from 'react-router-dom';
import {useEffect, useState} from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { ethers } from "ethers";
import CreateListingModal from "../components/CreateListingModal";
import MarketplaceInlineMenu from "../components/MarketplaceInlineMenu";
import {useAccount, useContractReads, useContractWrite, usePrepareContractWrite, useWaitForTransaction} from "wagmi";
import utils from "../Utils";
import toast from 'react-hot-toast';



function CreateListing(props) {
    const { tokenId } = useParams();
    const navigate = useNavigate();

    const {
        moonbirdsContractAddress,
        moonbirdsContractAbi,
        birdswapContractAddress,
        birdswapContractAbi,
        moonbirdsRoyaltyFeeBps
    } = props;

    const { address, isConnected } = useAccount();


    const [price, setPrice] = useState(0);
    const [royaltyBps, setRoyaltyBps] = useState(moonbirdsRoyaltyFeeBps);
    const [buyerAddress, setBuyerAddress] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [nestingPeriodData, setNestingPeriodData] = useState(null);
    const [owner, setOwner] = useState(null);
    const [serviceFeeBps, setServiceFeeBps] = useState(0);

    const { data: moonbirdsData, isError: moonbirdsContractError, isLoading: isMoonbirdsDataLoading } = useContractReads({
        contracts: [
            {
                addressOrName: moonbirdsContractAddress,
                contractInterface: moonbirdsContractAbi,
                functionName: 'ownerOf',
                args: [tokenId],
                cacheOnBlock: true
            },
            {
                addressOrName: moonbirdsContractAddress,
                contractInterface: moonbirdsContractAbi,
                functionName: 'nestingPeriod',
                args: [tokenId]
            },
            {
                addressOrName: birdswapContractAddress,
                contractInterface: birdswapContractAbi,
                functionName: 'marketplaceFeeBps',
                cacheOnBlock: true
            },
        ]
    });

    const { config: createAskConfig } = usePrepareContractWrite({
        addressOrName: birdswapContractAddress,
        contractInterface: birdswapContractAbi,
        functionName: 'createAsk',
        args: [tokenId, buyerAddress, ethers.utils.parseEther(`${price}`), moonbirdsRoyaltyFeeBps]
    });
    const { data: createAskData, isLoading: isCreateAskTxInputLoading, writeAsync: writeCreateAsk } = useContractWrite({
        ...createAskConfig,
        onError(error) {
            console.log(error, error.code, error.message);
            if (error.code === 'ACTION_REJECTED') {
                toast.error('You rejected the transaction.');
            } else {
                toast.error(error.message || 'Unexpected error occurred');
            }
        }
    });

    const { data: createAskCompleteData, isError: isCreateAskError, isLoading: isCreateAskLoading, isSuccess: isCreateAskSuccess } = useWaitForTransaction({
        hash: createAskData?.hash,
    });

    const { config: safeTransferWhileNestingConfig } = usePrepareContractWrite({
        addressOrName: moonbirdsContractAddress,
        contractInterface: moonbirdsContractAbi,
        functionName: 'safeTransferWhileNesting',
        args: [address, birdswapContractAddress, tokenId],
        enabled: isCreateAskSuccess
    });

    // error thrown because listing is not created before tranasction is crafted
    const { data: safeTransferWhileNestingData, isLoading: isSafeTransferWhileNestingInputLoading, writeAsync: writeSafeTransferWhileNesting } = useContractWrite({
        ...safeTransferWhileNestingConfig,
        onError(error) {
            console.log(error, error.code, error.message);
            if (error.code === 'ACTION_REJECTED') {
                toast.error('You rejected the transaction.');
            } else {
                toast.error(error.message || 'Unexpected error occurred');
            }
        }
    });

    const { data: safeTransferWhileNestingCompleteData, isError: isSafeTransferWhileNestingError, isLoading: isSafeTransferWhileNestingLoading, isSuccess: isSafeTransferWhileNestingSuccess } = useWaitForTransaction({
        hash: safeTransferWhileNestingData?.hash
    });

    useEffect(() => {
        console.log('Contract data: ', moonbirdsData);
        if (!isMoonbirdsDataLoading) {
            setOwner(moonbirdsData[0]);
            setNestingPeriodData(moonbirdsData[1]);
            try {
                // BigNumber format
                setServiceFeeBps(moonbirdsData[2].toNumber());
            } catch (error) {
                //swallow
             console.error(error);
            }
        }

    }, [isMoonbirdsDataLoading]);

    useEffect(() => {
        if (isSafeTransferWhileNestingSuccess) {
            toast.success('Bird successfully transferred to escrow!');
            navigate(`/bird/${tokenId}`);
        }
        else if (isCreateAskSuccess) {
            toast.success('Listing created successfully!', { duration: 5000 });
        }
    }, [isCreateAskSuccess, isSafeTransferWhileNestingSuccess]);


    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);


    function createListing() {
        console.log(price, royaltyBps, buyerAddress);
        handleShow();
    }

    function isButtonDisabled() {
        return (
            isNaN(price) || price < 0 ||
            isNaN(royaltyBps) || royaltyBps < 0 || royaltyBps > 10000 ||
            !ethers.utils.isAddress(buyerAddress) || nestingPeriodData?.nesting !== true
        )
    }

    function renderListingForm() {

        return (
            <div className="grid grid-cols-6 border-t-2 mt-4">
                <div className="col-start-1 col-end-7 text-xl my-4">Price</div>
                <InputGroup className="col-start-1 col-end-3" onChange={(e) => {
                    if (e.target.value === '') {
                        setPrice(0);
                    } else {
                        setPrice(e.target.value)
                    }
                }} >
                    <DropdownButton
                        variant="outline-secondary"
                        title="ETH"
                        id="input-group-dropdown-1"
                    >
                        <Dropdown.Item href="#">ETH</Dropdown.Item>
                    </DropdownButton>
                    <Form.Control type="number" min={0} defaultValue={0}/>
                </InputGroup>
                <div className="col-start-1 col-end-7 text-xl my-4">Set Creator royalty</div>
                <InputGroup className="col-start-1 col-end-2" onChange={(e) => setRoyaltyBps(e.target.value * 100)}>
                    <Form.Control
                        min={0}
                        max={10}
                        defaultValue={0}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                </InputGroup>
                <Form.Text className="col-start-1 col-end-7" muted>
                    Set the percentage royalties of the sale that will go to the NFT's creator.<br/> The default creator royalties for Moonbirds is 5%.
                </Form.Text>
                <div className="col-start-1 col-end-7 text-xl my-4">Buyer address</div>
                <div className="col-start-1 col-end-3">
                    <Form.Control
                        type="text"
                        id="buyerAddress"
                        placeholder="0xfa99....."
                        onChange={(e) => setBuyerAddress(e.target.value)}
                    />
                </div>
                <Form.Text className="col-start-1 col-end-7" muted>
                    This listing will only be valid for this buyer.
                </Form.Text>

                <Button className="col-start-1 col-end-2 my-4"
                        variant={nestingPeriodData?.nesting ? 'primary' : 'danger'}
                        onClick={nestingPeriodData?.nesting ? createListing : () => {}}
                        disabled={isButtonDisabled()}
                >{nestingPeriodData?.nesting ? 'Create listing' : 'Nest your bird before creating a listing'}</Button>
            </div>
        );
    }

    let nestingElement = null;

    if (nestingPeriodData?.current && nestingPeriodData?.nesting === true) {
        const daysNested = utils.getNestingDurationInDays(nestingPeriodData);
        if (daysNested > 0) {
            nestingElement = <div className="my-4">{`Days nested: ${daysNested}`}</div>;
        } else {
            nestingElement = <div className="my-4">{`Days nested: < 1`}</div>;
        }
    } else if (nestingPeriodData?.nesting === false) {
        nestingElement = <div className="my-4">Unnested</div>;
    }

    return (
        <div className="m-4">
            <div className="mt-9 text-2xl">Create new private listing</div>
            <div className="flex flex-row">
                <div className="basis-1/2 mt-4">
                    <img className="rounded-lg" height={300} width={300} src={utils.getMoonbirdImgSrc(tokenId)} alt={`Image for bird #${tokenId}`}/>
                </div>
                <div className="basis-1/2 mt-4">
                    <div className="text-2xl">{`Moonbird #${tokenId}`}</div>
                    {nestingElement}
                    {/*<div className="my-4">{`Nesting tier: Silver`}</div>*/}
                    <MarketplaceInlineMenu tokenId={tokenId}/>
                </div>
            </div>
            {renderListingForm()}
            <CreateListingModal
                onHide={handleClose}
                show={showModal}
                royaltyBps={royaltyBps}
                price={price}
                writeCreateAsk={writeCreateAsk}
                createAskSuccess={isCreateAskSuccess}
                isCreateAskTxInputLoading={isCreateAskTxInputLoading}
                isCreateAskTxLoading={isCreateAskLoading}
                writeSafeTransferWhileNesting={writeSafeTransferWhileNesting}
                safeTransferWhileNestingSuccess={isSafeTransferWhileNestingSuccess}
                isSafeTransferWhileNestingInputLoading={isSafeTransferWhileNestingInputLoading}
                isSafeTransferWhileNestingLoading={isSafeTransferWhileNestingLoading}
                tokenId={tokenId}
                title={'Complete your listing'}
                skipTransfer={false}
                serviceFeeBps={serviceFeeBps}
            />
        </div>
    );

}

export default CreateListing;
